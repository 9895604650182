import { graphql, useStaticQuery } from 'gatsby';
import Image from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";
import { getValue } from '../helper/translation';



const Gallery = ({ images, summaryGallery, node_locale, needsTitle }) => {
  const { microcopy } = useStaticQuery(graphql`
  {
    microcopy: allContentfulMicrocopy {
      edges {
        node {
          node_locale
          key
          value
        }
      }
    }
  }
`);

const localizedMicrocopy = {};

microcopy.edges
  .map(edge => edge.node)
  .forEach(node => {
    if (node.node_locale === node_locale) {
      localizedMicrocopy[node.key] = node.value;
    }
  });
  return(  
  <div className="gallery">
    {needsTitle && <h3 className="title font-weight-normal">{getValue("labels.impressions", localizedMicrocopy)}</h3>}
    <Carousel>
      {images.map(image => {
        return (
          !summaryGallery ? (
            <Carousel.Item>
              <Image fluid={image.fluid} className="d-block w-100" />
            </Carousel.Item>
          )  : (
            <Carousel.Item>
              <Image fluid={image.image.fluid} className="d-block w-100 summaryImage" />
            </Carousel.Item>
          )
        );
      })}
    </Carousel>
  </div>
)};

/* PropTypes */
Gallery.propTypes = {
  images: PropTypes.array,
};
Gallery.defaultProps = {
  needsTitle: false
};

export default Gallery;
